import "./WorkCardStyles.css";

import React from 'react';


const WorkCard = (props) => {
    return (

  <div className="project-card">
   <img src={props.imgsrc} alt="image"/>
   <h2 className="project-title">{props.title}</h2>
   <div className="pro-details">
   <p>{props.text}</p>
   <div className="pro-btns">
     {/* <NavLink to="props.view" className="btn">View</NavLink> */}
     {/* <NavLink to="url.com" className="btn">Source</NavLink> */}
   </div>
   </div>
  </div>
//   </div>
//     </div>

//    <div className="project-card">
//    <img src={pro1} alt="image"/>
//    <h2 className="projet-title">Project Title</h2>
//    <div className="pro-details">
//    <p>This is a paragraph</p>
//    <div className="pro-btns">
//      <NavLink to="url.com" className="btn">View</NavLink>
//      <NavLink to="url.com" className="btn">Source</NavLink>
//    </div>
//    </div>
  )
}

export default WorkCard;